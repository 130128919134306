<template>
  <div>
    <p class="mb-3">
      Which of the following elements/ions would have the lowest
      <b>first</b> ionization energy?
    </p>

    <v-form @submit.prevent="submitResponse">
      <v-radio-group v-model="inputs.input1" class="ml-8" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question204',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    versionValue() {
      return this.taskState.getValueBySymbol('versionValue').content;
    },
    options1() {
      if (this.versionValue.value === 1) {
        return [
          {expression: '$\\text{Ca}$', value: 'ca'},
          {expression: '$\\text{Mg}$', value: 'mg'},
          {expression: '$\\text{Ba}$', value: 'ba'},
          {expression: '$\\text{Sr}$', value: 'sr'},
          {expression: '$\\text{Mg}^{2+}$', value: 'mg2+'},
        ];
      } else if (this.versionValue.value === 2) {
        return [
          {expression: '$\\text{K}$', value: 'k'},
          {expression: '$\\text{Na}$', value: 'na'},
          {expression: '$\\text{Cs}$', value: 'cs'},
          {expression: '$\\text{Rb}$', value: 'rb'},
          {expression: '$\\text{Na}^{+}$', value: 'na+'},
        ];
      } else if (this.versionValue.value === 3) {
        return [
          {expression: '$\\text{Cl}$', value: 'cl'},
          {expression: '$\\text{F}$', value: 'f'},
          {expression: '$\\text{I}$', value: 'i'},
          {expression: '$\\text{Br}$', value: 'br'},
          {expression: '$\\text{F}^{+}$', value: 'f+'},
        ];
      } else if (this.versionValue.value === 4) {
        return [
          {expression: '$\\text{O}^{+}$', value: 'o+'},
          {expression: '$\\text{O}$', value: 'o'},
          {expression: '$\\text{Te}$', value: 'te'},
          {expression: '$\\text{S}$', value: 's'},
          {expression: '$\\text{Se}$', value: 'se'},
        ];
      } else {
        return [];
      }
    },
  },
};
</script>
